<template>
    <div class="custom-style-navbar p-5 flex flex-col justify-between bg-white">
        <div class="flex flex-col items-center divide-y-2">
            <img :src="logo" class="mt-4" />
            <!-- <unicon v-if="!mobile" class="p-2 icon-normal" name="times-circle" @click="close" /> -->
        </div>

        <ul>
            <li class="flex my-2 cursor-pointer" v-for="(options, index) in filterNavs" :key="index">
                <div
                    :class="[currentRoute == options.name ? 'bg-primary' : 'bg-secondary']"
                    class="flex-1 py-2 text-center rounded-card cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    @click="pushRoute(options.route)"
                >
                    <span class="text-18 text-white">{{ options.name }}</span>
                </div>
            </li>
            <li class="flex my-2 cursor-pointer">
                <a
                    href="https://status.services.eludico.io/status/conecta"
                    target="_blank"
                    class="flex-1 py-2 text-center rounded-card cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 bg-secondary"
                >
                <span class="text-18 text-white">C.onecta status</span>
                </a>
            </li>
        </ul>

        <div
            class="flex items-center justify-center m-2 cursor-pointer py-2 rounded-card bg-warning transition duration-500 ease-in-out transform hover:-translate-y-2 hover:scale-105"
            @click="logoff"
        >
            <i class="pi pi-power-off text-white" style="font-size: 1.5rem"></i>
        </div>
    </div>
</template>

<script>
    import { computed, onMounted, ref } from 'vue-demi';
    import { useRoute, useRouter } from 'vue-router';
    import authentication from '@/plugins/authentication';
    export default {
        setup() {
            const router = useRouter();
            const route = useRoute();

            const isOpen = ref(false);
            const optionsNav = ref([]);
            const logo = require('@/assets/img/logo.png');

            function pushRoute(path) {
                router.push(path);
            }
            function close() {
                isOpen.value = !isOpen.value;
            }
            function logoff() {
                authentication.logout();
                router.push('/login');
            }

            const currentRoute = computed(() => {
                console.log(route.meta.module);
                return route.meta.module;
            });

            const filterNavs = computed(() => {
                return optionsNav.value.filter((item) => {
                    return item.showNav;
                });
            });

            onMounted(() => {
                optionsNav.value = router.options.routes[2].children.map((item) => {
                    return {
                        name: item.meta.module,
                        route: item.path,
                        mode: item.meta.mode,
                        showNav: item.meta.showNav,
                    };
                });
            });

            return { pushRoute, close, logoff, currentRoute, isOpen, optionsNav, logo, filterNavs };
        },
    };
</script>

<style lang="scss">
    .custom-style-navbar {
        width: 200px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
        border-radius: 0 2rem 2rem 0;
        position: fixed;
        height: 100vh;
        transition: all 0.3s;
        z-index: 98900;

        &.mobile-active {
            img {
                width: 86%;
                height: auto;
            }

            &.active {
                transform: translateX(-200px);
            }
        }
    }
</style>
